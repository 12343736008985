.our-ceo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.our-advisory-ceo {
  align-items: center;
}
.our-advisory-content-container {
  display: flex;
  flex-direction: row;
  gap: 60px;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 900px) {
  .our-advisory-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.job-banner-content {
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: bold;
  letter-spacing: 2px;
  margin-top: 60px;
}

.jobs-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
}
.jobs-content-title {
  text-transform: uppercase;
}
.jobs-content-link {
  color: black;
  text-decoration: none;
}
.jobs-content-link:hover {
  color: #44b9b9;
}
.jobs-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 100px;
  margin: 50px;
}
.job-container {
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.75);
  background-size: cover;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-blend-mode: overlay;
  border-radius: 10px;
  color: white;
  text-align: center;
}
.job-requirements-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 150px;
  align-items: center;
}
.job-title {
  text-transform: uppercase;
  font-size: 2rem;
}
.job-education {
  width: 70%;
}
@media screen and (max-width: 500px) {
  .job-banner-content {
    font-size: 2rem;
    width: 95%;
  }
}
@media screen and (max-width: 600px) {
  .jobs-content-description {
    width: 90%;
    text-align: center;
  }
  .jobs-info-container {
    flex-direction: column;
  }
}

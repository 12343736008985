body {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  overflow-x: hidden;
  background-color: azure;
  margin: 0;
  overflow-x: hidden;
}
.content-main-header {
  text-align: center;
  text-transform: uppercase;
}
.banner-main {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-position: top;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}
.banner-main-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 250px;
  margin-top: 50px;
}
.banner-content-mt {
  margin-top: 130px;
}
@media screen and (max-width: 920px) {
  .banner-content-mt {
    margin-top: 0;
  }
}

.our-vision-image-title {
  text-transform: uppercase;
  font-size: 3rem;
  letter-spacing: 2px;
}
.our-vision-image-description {
  font-size: 1.5rem;
}
.our-vision-content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.ceo-message-container,
.business-approach-container,
.philosophy-container,
.construction-process-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 50px 0;
}
.ceo-message-image {
  width: 40%;
  border-radius: 10px;
  margin-right: 10px;
}
.ceo-message-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  margin: 25px 50px;
  gap: 50px;
  border-radius: 25px;
  text-justify: inter-word;
  text-align: justify;
}
.ceo-message {
  align-self: center;
  width: 45%;
  margin: 25px 50px;
  letter-spacing: 1.2px;
}
.business-approach-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.business-approach-content {
  width: 70%;
  letter-spacing: 1.2px;
  font-weight: 500;
}
.philosophies {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  gap: 25px;
  margin: 50px;
}
.project-involvement-content,
.cost-strategy-content {
  text-align: justify;
  text-justify: inter-word;
}
.construction-process-steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}
.construction-detail-title-list-item {
  font-weight: bold;
  font-size: 1.1rem;
}
@media screen and (max-width: 900px) {
  .ceo-message-content-container {
    flex-direction: column;
    margin: 25px;
  }
  .ceo-message {
    width: 90%;
  }
}
@media screen and (max-width: 830px) {
  .ceo-message-image {
    width: 60%;
    margin-top: 15px;
  }
}

.our-team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.our-team-header {
  text-transform: uppercase;
  margin: 10px;
}
.team-member-image {
  width: 100%;
  height: 250px;
  border-radius: 10px 10px 0 0;
}
.our-team-content-container {
  margin-top: 50px;
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.team-member-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 350px;
  background-color: white;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.39);
  border-radius: 10px;
}
.team-member-container:hover {
  cursor: pointer;
}
.team-member-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.team-member-role {
  font-weight: 200;
  letter-spacing: 0.7px;
}
@media screen and (max-width: 800px) {
  .our-team-info {
    width: 90%;
    text-align: center;
  }
}

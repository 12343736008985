.about-us-container {
  margin: 200px 50px 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.about-us-container-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.about-us-content {
  text-align: justify;
  text-justify: inter-word;
  font-size: 1rem;
  line-height: 1.5rem;
}

.core-values-images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 75px;
}

.core-image-data {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 300px;
}
.core-values-image {
  width: 300px;
  height: 300px;
}
.core-values-image-data-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: center;
}
.core-values-image-description {
  text-align: justify;
  width: 90%;
  text-justify: inter-word;
  align-self: center;
}
.banner2 {
  margin: 120px 0 !important;
  height: 700px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  max-height: 500px;
  background-position: top;
  display: flex;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-2-header {
  color: white;
  text-align: center;
  width: 75%;
  font-size: 2.5rem;
  font-weight: 400;
  letter-spacing: 1.75px;
}
.banner-link {
  text-decoration: none;
  color: white;
  margin-top: 75px;
  background-color: #44b9b9;
  padding: 20px 30px;
  border-radius: 10px;
}
.banner-link:hover {
  background-color: #44b9b99f;
}
.banner-link-content {
  font-size: 1.25rem;
  font-weight: 200;
}
.board-of-directors-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.core-value-title {
  text-transform: capitalize;
}
.board-of-directors {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 50px 0;
}
.bdr {
  flex: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.board-of-directors-left,
.board-of-directors-right {
  margin-top: 75px;
}
.our-advisory {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 75px;
}
@media screen and (max-width: 1020px) {
  .about-us-container {
    margin: 50px 20px;
  }
  .core-values-images-container {
    flex-direction: column;
    margin-top: 50px;
    align-items: center;
  }
  .core-image-data {
    width: 90%;
    margin-bottom: 50px;
  }
  .core-values-image {
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .banner2 {
    margin-bottom: 50px !important;
  }
  .board-of-directors {
    flex-direction: column;
    align-items: center;
  }
  .board-of-directors-left,
  .board-of-directors-right {
    margin: 10px;
  }
}

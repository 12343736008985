.home-banner {
  margin-bottom: 50px;
}
.home-banner-content-header {
  font-size: 3rem;
  letter-spacing: 2px;
  width: 90%;
}
.home-banner-content-button {
  font-size: 1.5rem;
  border: none;
  padding: 0.75rem 1rem;
  background-color: #44b9b9;
  border-radius: 15px;
}
.home-banner-content-button:hover {
  background-color: #44b9b9b2;
  cursor: pointer;
}
.home-banner-link {
  color: white;
  text-decoration: none;
}
@media screen and (max-width: 430px) {
  .home-banner-content-header {
    font-size: 2rem;
  }
}

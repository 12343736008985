.card-holder-container {
  margin-top: 90px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
}
@media screen and (max-width: 1019px) {
  .card-holder-container {
    margin-top: 0;
  }
}

.footer-container {
  background-repeat: no-repeat;
  margin-top: 80px;
  background-color: #23294f;
  color: white;
  height: 700px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 50px;
}
.footer-logo {
  display: flex;
  flex-direction: column;
}
.footer-logo-text {
  margin-top: 20px;
  width: 300px;
}
.footer-company-logo {
  width: 320px;
  height: 250px;
}
.footer-links {
  display: flex;
  flex-direction: column;
  margin: 50px 0;
  gap: 50px;
  font-size: 1.5rem;
}
.footer-contacts {
  margin-top: 10px;
  width: 300px;
}
.contact-container {
  display: flex;
  flex-direction: column;
}
.contact-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 70%;
}
.contact-title > svg {
  width: 25px;
}
.contact-link {
  text-decoration: none;
  color: white;
}

.footer-contact-title-content {
  margin-left: 25px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.footer-socials-large {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 350px;
}
.footer-link {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  font-size: 1.5rem;
}
.contact-link:hover,
.footer-link:hover {
  color: #44b9b9;
  transform: scale(1.01);
}
.footer-copyrights {
  background-color: #44b9b9;
}
.copyrights-content-container {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  color: white;
  font-size: 1rem;
  letter-spacing: 1px;
}
.credits-logo {
  display: flex;
  flex-direction: row;
}
.credit-logo > svg {
  width: 20px;
  margin: 0 20px;
}
.credits-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer-link-small > svg {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 550px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }
  .footer-company-logo {
    width: 175px;
    height: 150px;
    margin-top: 15px;
  }
  .footer-contacts {
    display: none;
  }
  .footer-links {
    flex-flow: row wrap;
    width: 75%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px !important;
  }

  .footer-socials-small {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
  }
  .credits-bar {
    display: none;
  }
}

.quotation-container {
  margin-top: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.quotation-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.quotation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 250px;
}

.quotation-link {
  text-decoration: none;
  background-color: #44b9b9;
  padding: 20px;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
}
.quotation-link:hover {
  background-color: #44b9b99d;
}
@media screen and (max-width: 360px) {
  .quotation-content-description {
    width: 80%;
    text-align: center;
  }
}

.card-container {
  width: 500px;
  height: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.card-image-container {
  background-size: cover;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  color: white;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 4em 0px rgba(0, 0, 0, 0.4);
}

.card-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-title {
  font-size: 1.5rem;
}
.card-description {
  width: 75%;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .card-image-container {
    align-items: center;
    justify-content: center;
  }
  .card-text-container {
    width: 90%;
  }
}
@media screen and (max-width: 1075px) {
  .card-container {
    width: 80%;
  }
}

.gallery-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 170px 0;
}

.our-portfolio-content {
  width: 60%;
  align-self: center;
  text-align: center;
  letter-spacing: 1.1px;
}
.our-portfolio-gallery {
  display: flex;
  flex-direction: column;
}
.our-portfolio-navbar {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  width: 75%;
  align-self: center;
  justify-content: center;
}
.our-portfolio-type {
  padding: 20px;
}
.active {
  background-color: #44b9b9;
  color: white;
}
.our-portfolio-type:hover {
  background-color: #44b9b9;
  color: white;
  cursor: pointer;
}
.our-portfolio-images-container {
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
  align-items: center;
  justify-content: space-around;
  margin: 50px;
  background-color: white;
}
.portfolio-image-container {
  background-repeat: no-repeat;

  background-size: contain;
  width: 330px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.portfolio-image-container:hover {
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
.portfolio-image-info {
  display: none;
}
.portfolio-image-container:hover > .portfolio-image-info {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 50%;
}
.portfolio-location {
  font-size: 1.65rem;
  text-transform: uppercase;
}
.portfolio-type {
  color: #44b9b9;
  font-size: 1.2rem;
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  .our-portfolio-navbar {
    flex-wrap: wrap;
    width: 95%;
  }
}
@media screen and (max-width: 1020px) {
  .gallery-container {
    margin-top: 50px;
  }
}

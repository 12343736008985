.our-services-container {
  margin: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.our-services-image {
  width: 400px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.39);
}
.our-services-contents {
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
}
.our-services-content-container {
  flex: 40%;
}
.our-services-content-container:hover > .our-services-r1 > svg {
  transform: scale(1.25);
  transition-delay: 0.2s;
}
.our-services-content-material {
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.4rem;
}
.project-involvement-container,
.cost-strategy-container {
  width: 40%;
}
@media screen and (min-width: 501px) and (max-width: 1050px) {
  .our-services-content-container {
    flex: 100%;
  }
}

.navbar-menu > svg {
  fill: #44b9b9;
}
.navbar-menu > a {
  margin-left: 10px;
  margin-top: 5px;
}
.navbar-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  width: 100%;
}
.navbar-menu-dropdown {
  display: flex;
  flex-direction: column;
  width: 40%;
  position: fixed;
  left: 55%;
  background-color: #ffffffe7;
}
.navbar-menu-link {
  padding: 15px;
  text-decoration: none;
  color: #44b9b9;
  border: 1px solid #44b9b9;
}
.navbar-menu-link:hover {
  background-color: #44b9b9;
  color: white;
}

.parallax {
  margin-top: 50px;
  height: 250px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.25);
  background-blend-mode: overlay;
  max-height: 500px;
  background-position: top;
  display: flex;
  align-items: center;
  width: 100%;
}
.banner-contents-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.banner-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.banner-content {
  color: rgb(6, 241, 241);
  font-size: 3rem;
  font-weight: 750;
  align-self: center;
}
.banner-content-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 750;
  text-transform: uppercase;
}
@media screen and (max-width: 850px) {
  .banner-content-title {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 430px) {
  .banner-content {
    font-size: 2rem;
  }
  .banner-content-title {
    font-size: 1rem;
  }
}

.contact-us-container {
  display: flex;
  flex-direction: column;
  margin: 180px 0;
  justify-content: space-around;
}
.meet-us-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.get-in-touch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.get-in-touch-with-us-description {
  font-size: 1.12rem;
  letter-spacing: 1.1px;
}
.get-in-touch-with-us-content {
  display: flex;
  flex-direction: row;
  width: 75%;
  background-color: white;
  padding: 50px;
  border-radius: 15px;
  margin-top: 50px;
  gap: 40px;
}
.get-in-touch-address-title {
  text-transform: uppercase;
  font-weight: bolder;
}
.get-in-touch-address-subtitle {
  text-transform: capitalize;
  letter-spacing: 1.25px;
}
.get-in-touch-address-content > a {
  text-decoration: none;
  color: black;
}
.get-in-touch-address-content > a:hover {
  text-decoration: none;
  color: #44b9b9;
}
.get-in-touch-mail-form {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}

.get-in-touch-mail {
  margin-top: 20px;
}
.left-row {
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}
.get-in-touch-mail-left-content {
  height: 40px;
  width: 220px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.253);
  padding-left: 10px;
  background-color: rgba(193, 193, 193, 0.11);
}
.mail-button {
  width: 235px;
  background-color: #44b9b9;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
}
.mail-button:hover {
  cursor: pointer;
  background-color: #44b9b99d;
}
.get-in-touch-mail-right-content {
  width: 220px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.253);
  background-color: rgba(193, 193, 193, 0.11);
  text-align: start;
  padding-left: 10px;
  padding-bottom: 60%;
  padding-top: 6%;
}
@media screen and (max-width: 1020px) {
  .contact-us-container {
    margin-top: 50px;
  }
  .meet-us-container {
    width: 100%;
  }
  .get-in-touch-container {
    margin-top: 50px;
  }
  .get-in-touch-with-us-description {
    width: 80%;
    text-align: center;
  }
  .get-in-touch-with-us-content {
    flex-direction: column;
  }
}
@media screen and (max-width: 750px) {
  .get-in-touch-mail-form {
    flex-direction: column;
  }
}

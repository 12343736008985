.our-info-content-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.6);
}
.our-info-container {
  width: 55%;
}
.our-info-team-image {
  width: 40%;
  height: 75%;
  border-radius: 5px;
}
.our-info-contents {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  justify-content: space-between;
}
.our-info-dropdown-select {
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  background-color: #44b9b9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.our-info-dropdown-select:hover {
  background-color: #44b9b99d;
  cursor: pointer;
}
.our-info-dropdown-select:hover > .our-info-header {
  color: black;
}
.our-info-header {
  color: white;
  font-size: 1.5rem;
}

.our-info-content {
  margin-left: 7px;
  background-color: white;
}

.our-info-intro {
  margin-top: 50px;
}
@media screen and (min-width: 851px) and (max-width: 1150px) {
  .our-info-dropdown-select {
    width: 90%;
  }
}
@media screen and (max-width: 850px) {
  .our-info-team-image {
    display: none;
  }
  .our-info-header {
    width: 100%;
  }
  .our-info-content-container {
    justify-content: center;
  }
}
@media screen and (max-width: 441px) {
  .our-info-header {
    font-size: 1rem;
  }
  .our-info-content {
    margin: 0;
  }
  .our-info-content-container {
    margin: 0;
  }
}

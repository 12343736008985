.navbar {
  position: fixed;
  padding: 20px 10px;
  top: 0;
  background-color: rgb(255, 255, 255);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  z-index: 10;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.75);
}
.navbar-logo {
  width: 170px;
  height: 100%;
}
.navbar-logo:hover {
  cursor: pointer;
}
.navbar-link {
  text-decoration: none;
  text-transform: uppercase;
  color: rgb(48, 49, 51);
  font-weight: 600;
}
.navbar-link:hover {
  color: #44b9b9;
  border-bottom: 3px solid #44b9b9;
}
.active-link {
  color: #44b9b9;
}

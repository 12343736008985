.customers-review-container {
  margin-top: 50px;
  min-height: 300px;
  padding-bottom: 60px;
}
.customer-review-container :hover {
  animation-play-state: paused;
  cursor: pointer;
}
.customer-review-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  overflow-x: hidden;
}

.customer-card-container {
  width: 600px;
  height: 350px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  margin: 0 10px;
  background-color: white;
}
.customer-card-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.customer-card-container:hover {
  background-color: #44b9b9;
  color: white;
  transition-delay: 0.004s;
}

.customer-address-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.customer-review-content {
  text-align: center;
  width: 80%;
  padding: 0 50px;
}
@media screen and (max-width: 430px) {
  .customer-card-container {
    width: 400px;
    height: 350px;
  }
  .customer-card-image {
    width: 50px;
    height: 50px;
  }
  /* .customer-review-content {
    width: 75%;
  } */
}
